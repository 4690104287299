import { AboutContainer } from 'modules/LandingPages/About/About'
import React from 'react'

const AboutPage = () => {
  return (
    <div>
      <AboutContainer/>
    </div>
  )
}

export default AboutPage
