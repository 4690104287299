import React from 'react'
import Products from '../../components/Products'

const ProductContainer = () => {
  return (
    <div>
      <Products/>
    </div>
  )
}

export default ProductContainer
