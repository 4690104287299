import { WhatsApp as WhatsappIcon } from "@mui/icons-material";
import { Box, Button, Link } from "@mui/material";

const WhatsApp = () => {
  return (
    <Box >
      <Button sx={{cursor:"pointer"}}>
        <Link href="https://wa.me/+17372821601">
        <WhatsappIcon sx={{ color: "white",bgcolor:"#00a040",p:.6, fontSize: "3.5em",borderRadius:"50%" }} />
        </Link>
      </Button>
    </Box>
  );
};

export default WhatsApp;