import  { useState } from "react";
import { Box, TextField } from "@mui/material";
import CommonButton from "components/Buttons/CommonButton";
import { COLORS } from "constant/colors";

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });

  console.log(formData)
  const handleChange = (e:any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  // service_cdy1clw
  const handleSubmit = (e:any) => {
    e.preventDefault();
    window.location.href = `mailto:office@ecomevibe.com.com?subject=${formData.subject}&body=${formData.message}`;
  };

  return (
    <Box
      sx={{
        p: 4,
        bgcolor: COLORS.black.blackGray,
        borderRadius: 2,
      }}
    >
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          placeholder="NAME"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 1, bgcolor: COLORS.light.white, borderRadius: 1 }}
        />
        <TextField
          type="text"
          placeholder="SUBJECT"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 1, bgcolor: COLORS.light.white, borderRadius: 1 }}
        />
     
        <TextField
          type="text"
          placeholder="MESSAGE"
          name="message"
          value={formData.message}
          onChange={handleChange}
          multiline
          rows={5}
          fullWidth
          sx={{ mb: 2, bgcolor: COLORS.light.white, borderRadius: 1 }}
        />
        <CommonButton title={"SEND MESSAGE"} type="submit"/>
      </form>
    </Box>
  );
};

export default Form;
