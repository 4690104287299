import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  BEAUTY_CATEGORY_PRODUCTS,
  FEATURE_PRODUCTS,
  HOME_KITCHEN_PRODUCTS,
  MEN_CATEGORY_PRODUCTS,
  WOMEN_CATEGORY_PRODUCTS,
} from "constant/data";
import { LANDING_PAGES } from "constant/routes";
import FeatureProducts from "modules/LandingPages/Home/components/FeatureProducts/FeatureProducts";
import { useLocation } from "react-router-dom";

const ProductsByCategory = (props: any) => {
  const { pathname } = useLocation();
  const { productsData } = props || {};
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (pathname === LANDING_PAGES.MEN) {
      setData(MEN_CATEGORY_PRODUCTS);
    } else if (pathname === LANDING_PAGES.WOMEN) {
      setData(WOMEN_CATEGORY_PRODUCTS);
    } else if (pathname === LANDING_PAGES.HOMEKANDITCHEN) {
      setData(HOME_KITCHEN_PRODUCTS);
    } else {
      setData(BEAUTY_CATEGORY_PRODUCTS);
    }
  }, [data, pathname]);

  return (
    <Box bgcolor={"white"} py={{ md: 7, sm: 4, xs: 2 }} px={2}>
      <Typography fontSize={"medium"} color={"gray"}>
        Home / {`${pathname.charAt(1).toUpperCase()}${pathname.slice(2)}`}
      </Typography>
      <FeatureProducts
        productsData={productsData?.length > 0 ? productsData : data}
      />
    </Box>
  );
};

export default ProductsByCategory;
