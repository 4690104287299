import React, { useEffect } from 'react'
import OffersCards from '../../components/OfferCard/OffersCards'
import Header from '../../components/Header/Header'
import FeatureProducts from '../../components/FeatureProducts/FeatureProducts'
import TimeOffers from '../../components/TimeOffers/TimeOffers'
import { Divider } from '@mui/material'
import LimitOfferCards from '../../components/LimtOfferCards/LimitOfferCards'
import { FEATURE_PRODUCTS } from 'constant/data'

const HomeContainers = (props:any) => {
  const [productsData, setProductsData] = React.useState<any[]>([]);
  
  useEffect(()=>{
    setProductsData(FEATURE_PRODUCTS)
  },[productsData])
  return (
    <div>
      <Header/>
      <OffersCards/>
      <FeatureProducts productsData={productsData}/>
      <Divider/>
      <TimeOffers bgImage={`url(https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2019/12/banner-03.jpg)`}/>
      <LimitOfferCards/>
    </div>
  )
}

export default HomeContainers
