import  { useContext, useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import FilterProducts from 'components/FilterProducts/FilterProducts'
import ProductsByCategory from 'components/ProductsByCategory/ProductsByCategory'
import { COLORS } from 'constant/colors'
import { FEATURE_PRODUCTS, HOME_KITCHEN_PRODUCTS } from 'constant/data'
import { CartContext } from 'context/cartContext'

const HomeAndKitchen = () => {
  const { search, price } = useContext(CartContext);
  const [productsData, setProductsData] = useState<any[]>([]);
  

// Accessory All category Products...
  useEffect(() => {
    if (search.length >= 2) {
      const filteredProducts = HOME_KITCHEN_PRODUCTS.filter((product:any) =>
        `${product.title.toLowerCase()}`.includes(search.toLowerCase())
      );
      setProductsData(filteredProducts);
      if (price[0] !== 0 || price[1] !== 100) {
        const filteredProductsFilterByPrice = filteredProducts.filter(
          (product) =>
            product.lowPrice > price[0] && product.highPrice < price[1]
        );
        setProductsData(filteredProductsFilterByPrice);
      }
    } else if (price[0] !== 0 || price[1] !== 100) {
      const filterProductsByPrice = HOME_KITCHEN_PRODUCTS.filter(
        (product) => product.lowPrice > price[0] && product.highPrice < price[1]
      );
      setProductsData(filterProductsByPrice);
    } else {
      setProductsData(HOME_KITCHEN_PRODUCTS);
    }
  }, [search, price]);
  return (
    <Box bgcolor={COLORS.black.lightGray} py={{md:10,sm:12}} >
      <Grid container spacing={{md:4}} pt={{md:10,sm:8,xs:5}} >
      <Grid item md={3} sm={4} xs={12}>
        <FilterProducts productsData={productsData}/>
      </Grid>
      <Grid item md={9} sm={8} xs={12}>
        <ProductsByCategory productsData={productsData} />
      </Grid>
    </Grid>
  </Box>
  )
}

export default HomeAndKitchen
