import { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import FilterProducts from "components/FilterProducts/FilterProducts";
import ProductsByCategory from "components/ProductsByCategory/ProductsByCategory";
import { COLORS } from "constant/colors";
import { WOMEN_CATEGORY_PRODUCTS } from "constant/data";
import { CartContext } from "context/cartContext";

const WomenPage = () => {
  const { search, price } = useContext(CartContext);
  const [productsData, setProductsData] = useState<any[]>([]);
  

  
// Women category Products...
  useEffect(() => {
    if (search.length >= 2) {
      const filteredProducts = WOMEN_CATEGORY_PRODUCTS.filter((product) =>
        `${product.title.toLowerCase()}`.includes(search.toLowerCase())
      );
      setProductsData(filteredProducts);
      if (price[0] !== 0 || price[1] !== 100) {
        const filteredProductsFilterByPrice = filteredProducts.filter(
          (product) =>
            product.lowPrice > price[0] && product.highPrice < price[1]
        );
        setProductsData(filteredProductsFilterByPrice);
      }
    } else if (price[0] !== 0 || price[1] !== 100) {
      const filterProductsByPrice = WOMEN_CATEGORY_PRODUCTS.filter(
        (product) => product.lowPrice > price[0] && product.highPrice < price[1]
      );
      setProductsData(filterProductsByPrice);
    } else {
      setProductsData(WOMEN_CATEGORY_PRODUCTS);
    }
  }, [search, price]);

  return (
    <Box bgcolor={COLORS.black.lightGray} py={{ md: 10, sm: 12 }}>
      <Grid container spacing={{ md: 4 }} pt={{ md: 10, sm: 8, xs: 5 }}>
        <Grid item md={3} sm={4} xs={12}>
          <FilterProducts productsData={productsData}/>
        </Grid>
        <Grid item md={9} sm={8} xs={12}>
          <ProductsByCategory productsData={productsData} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WomenPage;
