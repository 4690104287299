import { ContactContainer } from 'modules/LandingPages/Contact/Contact'
import React from 'react'

const ContactPage = () => {
  return (
    <div>
      <ContactContainer/>
    </div>
  )
}

export default ContactPage
