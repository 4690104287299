import { HomeContainer } from 'modules/LandingPages/Home/Home'
import React from 'react'

const HomePage = () => {
  return (
    <div>
      <HomeContainer/>
    </div>
  )
}

export default HomePage
