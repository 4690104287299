import { Box, Grid } from "@mui/material";
import FilterProducts from "components/FilterProducts/FilterProducts";
import ProductsByCategory from "components/ProductsByCategory/ProductsByCategory";
import { COLORS } from "constant/colors";
import { BEAUTY_CATEGORY_PRODUCTS, FEATURE_PRODUCTS, MEN_CATEGORY_PRODUCTS, WOMEN_CATEGORY_PRODUCTS } from "constant/data";
import { CartContext } from "context/cartContext";
import { useContext, useEffect, useState } from "react";

const AllCategoriesPage = () => {
  const { search, price } = useContext(CartContext);
  const [productsData, setProductsData] = useState<any[]>([]);
  
// All category Products...
const allProducts=[...FEATURE_PRODUCTS,...MEN_CATEGORY_PRODUCTS,...WOMEN_CATEGORY_PRODUCTS,...BEAUTY_CATEGORY_PRODUCTS]
function shuffleArray(array:any) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

const shuffledProducts = shuffleArray(allProducts);
useEffect(() => {
    if (search.length >= 2) {
      const filteredProducts = shuffledProducts.filter((product:any) =>
        `${product.title.toLowerCase()}`.includes(search.toLowerCase())
      );
      setProductsData(filteredProducts);
      if (price[0] !== 0 || price[1] !== 100) {
        const filteredProductsFilterByPrice = filteredProducts.filter(
          (product:any) =>
            product.lowPrice > price[0] && product.highPrice < price[1]
        );
        setProductsData(filteredProductsFilterByPrice);
      }
    } else if (price[0] !== 0 || price[1] !== 100) {
      const filterProductsByPrice = FEATURE_PRODUCTS.filter(
        (product) => product.lowPrice > price[0] && product.highPrice < price[1]
      );
      setProductsData(filterProductsByPrice);
    } else {
      setProductsData(allProducts);
    }
  }, [search, price]);

  return (
    <Box bgcolor={COLORS.black.lightGray} py={{ md: 10, sm: 12 }}>
      <Grid container spacing={{ md: 4 }} pt={{ md: 10, sm: 8, xs: 5 }}>
        <Grid item md={3} sm={4}>
          <FilterProducts productsData={productsData}/>
        </Grid>
        <Grid item md={9} sm={8}>
          <ProductsByCategory productsData={productsData} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllCategoriesPage;
