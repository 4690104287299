import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { COLORS } from "constant/colors";

const Information = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 8, p: 3 }}>
      <Grid container spacing={0} sx={{ bgcolor: COLORS.light.white }}>
        <Grid item md={6}>
          <Typography
            sx={{
              fontSize: { md: "3em", sm: "2em", xs: "2em" },
              p: 2,
              fontWeight: "bold",
              mt: 5,
            }}
          >
            Who We Are
          </Typography>
          <Typography
            sx={{
              p: 2,
              fontWeight: 100,
            }}
          >
            We are a passionate team dedicated to delivering exceptional
            products and services. With a focus on quality, innovation, and
            customer satisfaction, we strive to offer the best shopping
            experience possible. Our commitment to excellence drives us to
            continuously improve and bring you the finest selections, unbeatable
            offers, and secure shopping from anywhere in the world.
          </Typography>
        </Grid>
        <Grid item md={6}>
          <img
            src="https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/slide-image-free-img.jpg"
            alt="Info Img"
            style={{ width: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Information;
