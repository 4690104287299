export const LANDING_PAGES = {
    HOME : '/',
    HOME_PAGE : '/home',
    ABOUT_PAGE: '/about',
    CONTACT_PAGE: '/contact',
    ALL_CATEGORIES:'/all_categories',
    WOMEN:'/women',
    MEN:'/men',
    BEAUTY:'/beauty',
    HOMEKANDITCHEN:'/home&kitchen',
    PRODUCTS_ID : '/products'
}