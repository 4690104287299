// show card in section
export const ADVERTISEMEMT_CARD = [
  {
    poster:
      "https://images.pexels.com/photos/26690370/pexels-photo-26690370/free-photo-of-a-bowl-of-cherries-on-a-table.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    title: "20% of Tank Top",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac dictum.",
  },
  {
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/men-fashion-free-img.jpg",
    title: "Latest Eyewear For You",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac dictum.",
  },
  {
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/footwear-free-img.jpg",
    title: "Lets Lorem Suit Up!",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac dictum.",
  },
];

// for products

export const FEATURE_PRODUCTS = [
  {
    id: 1,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-bag3-300x300.jpg",
    title: "Red Purse",
    category: "Women",
    price: 130.0,
    quantity: 1,
    lowPrice: 70,
    highPrice: 90,
  },
  {
    id: 2,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2021/03/sports-shoe1-300x300.jpg",
    title: "DNK Blue Shoes",
    category: "Men",
    lowPrice: 50,
    quantity: 3,
    highPrice: 80,
  },
  {
    id: 3,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-m-jeans1-300x300.jpg",
    title: "Brown Jeans",
    category: "Men",
    lowPrice: 120,
    quantity: 4,
    highPrice: 160,
  },
  {
    id: 4,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-w-jeans2-300x300.jpg",
    title: "Demin Jeans",
    category: "Women",
    lowPrice: 60,
    highPrice: 120,
    quantity: 10,
  },
  {
    id: 5,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-w-jeans4-300x300.jpg",
    title: "Gray Jeans",
    category: "Women",
    lowPrice: 120,
    highPrice: 180,
    quantity: 11,
  },
  {
    id: 6,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-w-jeans1-300x300.jpg",
    title: "Blue Shirt",
    category: "Women",
    lowPrice: 140,
    quantity: 9,
    highPrice: 160,
  },
  {
    id: 7,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-accessory2-300x300.jpg",
    title: "Anchor Bracelet",
    category: "Women",
    lowPrice: 250,
    quantity: 13,
    highPrice: 360,
  },
  {
    id: 8,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-accessory1-300x300.jpg",
    title: "Bango Bacelet",
    category: "Women",
    lowPrice: 200,
    quantity: 6,
    highPrice: 230,
  },
  {
    id: 9,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-bag1-300x300.jpg",
    title: "Brown Purse",
    category: "Women",
    lowPrice: 130,
    quantity: 11,
    highPrice: 150,
  },
  {
    id: 10,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-bag3-300x300.jpg",
    title: "Red Purse",
    category: "Women",
    lowPrice: 100,
    quantity: 8,
    highPrice: 130,
  },

  {
    id: 11,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2021/03/sports-shoe3-300x300.jpg",
    title: "DNK Yellow Shoes",
    category: "Men",
    lowPrice: 70,
    highPrice: 120,
    quantity: 9,
  },
  {
    id: 12,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2021/03/sports-shoe1-300x300.jpg",
    title: "DNK Blue Shoes",
    category: "Men",
    lowPrice: 200,
    highPrice: 240,
    quantity: 5,
  },
  {
    id: 13,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-m-jeans1-300x300.jpg",
    title: "Brown Jeans",
    category: "Men",
    lowPrice: 120,
    highPrice: 160,
    quantity: 6,
  },
  {
    id: 14,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-w-jeans2-300x300.jpg",
    title: "Demin Jeans",
    category: "Women",
    lowPrice: 100,
    highPrice: 120,
    quantity: 7,
  },
  {
    id: 15,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-w-jeans4-300x300.jpg",
    title: "Gray Jeans",
    category: "Women",
    lowPrice: 130,
    highPrice: 180,
    quantity: 0,
  },
  {
    id: 16,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-w-jeans1-300x300.jpg",
    title: "Blue Shirt",
    category: "Women",
    lowPrice: 100,
    highPrice: 160,
    quantity: 1,
  },
  {
    id: 17,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-accessory2-300x300.jpg",
    title: "Anchor Bracelet",
    category: "Women",
    lowPrice: 250,
    highPrice: 360,
    quantity: 10,
  },
  {
    id: 18,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-accessory1-300x300.jpg",
    title: "Bango Bacelet",
    category: "Women",
    lowPrice: 200,
    highPrice: 230,
    quantity: 0,
  },
  {
    id: 19,
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2017/12/product-bag1-300x300.jpg",
    title: "Brown Purse",
    category: "Women",
    lowPrice: 100,
    highPrice: 150,
    quantity: 14,
  },
];

// limit time offer Cards
export const OFFER_CARD = [
  {
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/globe-free-img.png",
    title: "Worldwide Shipping",
    description:
      " Delivering our products to your doorstep, no matter where you are. Enjoy global access to our exclusive collections.",
  },
  {
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/quality-free-img.png",
    title: "Best Quality",
    description:
      "Crafted with precision and care, our products are designed to meet the highest standards of quality and durability.",
  },
  {
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/tag-free-img.png",
    title: "Best Offers",
    description:
      "Unlock unbeatable deals and discounts on our top-selling items, making premium quality more affordable.",
  },
  {
    poster:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/lock-free-img.png",
    title: "Secure Payments",
    description:
      "Shop with confidence, knowing your transactions are protected by advanced security measures for a seamless and safe experience.",
  },
];

// Team
export const OUR_TEAMS = [
  {
    profile:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/team2-free-img.png",
    fullName: "Harvey Spector",
    job: "Founder - CEO",
  },
  {
    profile:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/team1-free-img.png",
    fullName: "Jess Pearson",
    job: "COO",
  },
  {
    profile:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/team3-free-img.png",
    fullName: "Rachel Zain",
    job: "Marketing Head",
  },
  {
    profile:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/team4free-img.png",
    fullName: "Luise Litt",
    job: "Lead Developer",
  },
  {
    profile:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/team5-free-img.png",
    fullName: "Katrina Bennett",
    job: "Intern Designer",
  },
  {
    profile:
      "https://websitedemos.net/brandstore-02/wp-content/uploads/sites/150/2018/12/team6-free-img.png",
    fullName: "Mike Ross",
    job: "Intern Designer",
  },
];

// contact page card for owner
export const OWNER_CARDS = [
  {
    title: "Sale",
    description: "Enjoy unbeatable discounts on our top products. Shop now and save big!",
  },
  {
    title: "Complaints",
    description: "Reach out with any issues, and we'll resolve them promptly.",
  },
  {
    title: "Returns",
    description: "Easy and hassle-free returns for a seamless shopping experience.",
  },
  {
    title: "Marketing",
    description: "Stay informed about our latest offers and exclusive deals. ",
  },
];

export const MEN_CATEGORY_PRODUCTS = [
  {
    id: 21,
    poster:
      "https://m.media-amazon.com/images/I/71T4Ag9XFOL._AC_SX385_.jpg",
    title: "Untucked Shirts",
    category: "Men",
    price: 100.0,
    quantity: 1,
    lowPrice: 50,
    highPrice: 70,
  },
  {
    id: 22,
    poster:
      "https://m.media-amazon.com/images/I/817jsmIVbtL._AC_SX425_.jpg",
    title: "Sun Protection shirts",
    category: "Men",
    price: 110.0,
    quantity: 1,
    lowPrice: 60,
    highPrice: 80,
  },
  {
    id: 23,
    poster:
      "https://m.media-amazon.com/images/I/81s0SFXxeJL._AC_SX385_.jpg",
    title: "Linen Shirts Short",
    category: "Men",
    price: 120.0,
    quantity: 1,
    lowPrice: 70,
    highPrice: 90,
  },
  {
    id: 24,
    poster:
      "https://m.media-amazon.com/images/I/61jQ-3AV8qL._AC_SY500_.jpg",
    title: "Aero Stretch Jeans",
    category: "Men",
    price: 130.0,
    quantity: 1,
    lowPrice: 80,
    highPrice: 100,
  },
  {
    id: 25,
    poster:
      "https://www.shutterstock.com/shutterstock/photos/614679410/display_1500/stock-photo-men-shirt-for-clothing-isolated-on-white-background-614679410.jpg",
    title: "Baseball Cap",
    category: "Men",
    price: 140.0,
    quantity: 1,
    lowPrice: 90,
    highPrice: 110,
  },
  {
    id: 26,
    poster:
      "https://www.shutterstock.com/shutterstock/photos/2344713571/display_1500/stock-photo-shirt-isolated-on-white-background-2344713571.jpg",
    title: "Sunglasses",
    category: "Men",
    price: 150.0,
    quantity: 1,
    lowPrice: 100,
    highPrice: 120,
  },
  {
    id: 27,
    poster:
      "https://www.shutterstock.com/shutterstock/photos/439306306/display_1500/stock-vector-vector-single-cartoon-sunglasses-439306306.jpg",
    title: "Messenger Bag",
    category: "Men",
    price: 160.0,
    quantity: 1,
    lowPrice: 110,
    highPrice: 130,
  },
  {
    id: 28,
    poster:
      "https://www.shutterstock.com/shutterstock/photos/1766921726/display_1500/stock-vector-black-futuristic-hipster-sunglasses-with-dark-glass-and-hud-interface-on-white-background-vector-1766921726.jpg",
    title: "Leather Belt",
    category: "Men",
    price: 170.0,
    quantity: 1,
    lowPrice: 120,
    highPrice: 140,
  },
  {
    id: 29,
    poster:
      "https://www.shutterstock.com/shutterstock/photos/2137350665/display_1500/stock-vector-neon-cyber-glasses-with-gradient-set-cyberpunk-purple-sunglasses-with-trendy-gold-frame-synthwave-2137350665.jpg",
    title: "Wallet",
    category: "Men",
    price: 180.0,
    quantity: 1,
    lowPrice: 130,
    highPrice: 150,
  },
  {
    id: 30,
    poster:
      "https://www.shutterstock.com/shutterstock/photos/2428326023/display_1500/stock-vector-vision-high-futuristic-technology-vr-goggles-on-human-virtual-reality-device-vr-modern-helmet-2428326023.jpg",
    title: "Jeans",
    category: "Men",
    price: 190.0,
    quantity: 1,
    lowPrice: 140,
    highPrice: 160,
  },
];

export const WOMEN_CATEGORY_PRODUCTS = [
  {
    id: 31,
    poster:
      "https://m.media-amazon.com/images/I/61cg-EMQHmL._AC_SY500_.jpg",
    title: "Pullover Sweaters",
    category: "Women",
    price: 105.0,
    quantity: 1,
    lowPrice: 55,
    highPrice: 75,
    description: "Elegant red dress suitable for all occasions.",
  },
  {
    id: 32,
    poster:
      "https://m.media-amazon.com/images/I/81VBGj9bxQL._AC_SY550_.jpg",
    title: " Women's Sweaters ",
    category: "Women",
    price: 115.0,
    quantity: 1,
    lowPrice: 65,
    highPrice: 85,
    description: "Stylish high heels perfect for evening wear.",
  },
  {
    id: 33,
    poster:
      "https://m.media-amazon.com/images/I/71YYyevj4XL._AC_SX385_.jpg",
    title: "Jackets Coats",
    category: "Women",
    price: 125.0,
    quantity: 1,
    lowPrice: 75,
    highPrice: 95,
    description: "Trendy handbag with ample space.",
  },
  {
    id: 34,
    poster:
      "https://www.shutterstock.com/shutterstock/photos/2200662683/display_1500/stock-photo-colored-makeup-powder-on-black-background-2200662683.jpg",
    title: "Necklace",
    category: "Women",
    price: 135.0,
    quantity: 1,
    lowPrice: 85,
    highPrice: 105,
    description: "Beautiful necklace with intricate design.",
  },
  {
    id: 35,
    poster:
      "https://www.shutterstock.com/shutterstock/photos/1274887837/display_1500/stock-photo-makeup-brushes-on-black-background-1274887837.jpg",
    title: "Silk Scarf",
    category: "Women",
    price: 145.0,
    quantity: 1,
    lowPrice: 95,
    highPrice: 115,
    description: "Soft silk scarf in vibrant colors.",
  },
  {
    id: 36,
    poster:
      "https://www.shutterstock.com/shutterstock/photos/1274887765/display_1500/stock-photo-makeup-brushes-on-black-background-1274887765.jpg",
    title: "Designer Sunglasses",
    category: "Women",
    price: 155.0,
    quantity: 1,
    lowPrice: 105,
    highPrice: 125,
    description: "Fashionable sunglasses with UV protection.",
  },
  {
    id: 37,
    poster:
      "https://www.shutterstock.com/shutterstock/photos/617116961/display_1500/stock-photo-set-of-flat-top-view-of-various-professional-female-cosmetics-brushes-for-makeup-and-eyelash-brush-617116961.jpg",
    title: "Flats",
    category: "Women",
    price: 165.0,
    quantity: 1,
    lowPrice: 115,
    highPrice: 135,
    description: "Comfortable flats for everyday wear.",
  },
];
export const BEAUTY_CATEGORY_PRODUCTS = [
  {
    id: 41,
    poster: "https://m.media-amazon.com/images/I/611W9+b8rcL._SX679_.jpg",
    title: "e.l.f., Monochromatic Multi Stick",
    category: "Beauty",
    price: 4.0,
    quantity: 1,
    lowPrice: 4,
    highPrice: 6,
    description:
      "PERFECT PIGMENT: Use this shimmery, luxuriously blendable cream-to-powder stick as your eyeshadow, lipstick, and blush for a cohesive monochromatic look.",
  },
  {
    id: 42,
    poster: "https://m.media-amazon.com/images/I/71HB8ShCY5L._SX679_.jpg",
    title: "Arvazallia Hydrating Argan Oil Hair Mask",
    category: "Beauty",
    price: 11.0,
    quantity: 1,
    lowPrice: 12,
    highPrice: 16,
    description: `Deeply hydrates and moisturizes dry, damaged hair leaving it soft, silky, and easier to manage
Repairs and restores weak, overprocessed hair improving texture, elasticity and shine`,
  },
  {
    id: 43,
    poster:
      "https://m.media-amazon.com/images/I/71JKC7rsK2L._SY879_.jpg",
    title: "Maskara",
    category: "Beauty",
    price: 5.0,
    quantity: 1,
    lowPrice: 4,
    highPrice: 5,
    description: "WATERPROOF PRINCESS: Get the voluminous false lash effects of the original in a waterproof formula with the Lash Princess False Lash Waterproof Mascara from essence!",
  },
  {
    id: 44,
    poster:
      "https://m.media-amazon.com/images/I/71gNu6O1WoL._SX522_.jpg",
    title: "Beauty Sleep Night Cream",
    category: "Beauty",
    price: 70.0,
    quantity: 1,
    lowPrice: 54,
    highPrice: 64,
    description: "Featuring proprietary Rest & Restore Technology, this night cream visibly improves: fine lines, wrinkles, dryness, dullness & loss of firmness. Moisturizer works overnight to improve surface cell renewal for visibly renewed skin.",
  },
  {
    id: 45,
    poster:
      "https://m.media-amazon.com/images/I/7162A-YaDZL._SX522_.jpg",
    title: "GrandeLASH-MD Lash Enhancing Serum",
    category: "Beauty",
    price: 70.0,
    quantity: 1,
    lowPrice: 36,
    highPrice: 68,
    description: "Award-winning lash enhancing serum created with vitamins peptides & amino acids for the appearance of longer, thicker looking lashes in just 4-6 weeks with full improvement in 3 months. Winner of Harper’s BAZAAR Anti-aging Award & Cosmo Beauty Award",
  },
  {
    id: 46,
    poster:
      "https://m.media-amazon.com/images/I/61rKgn8qdLL._SY879_.jpg",
    title: "Nivea Invisible for Black & White Clear Roll-On Anti-Perspirant",
    category: "Beauty",
    price: 70.0,
    quantity: 1,
    lowPrice: 36,
    highPrice: 68,
  },
  {
    id: 47,
    poster:
      "https://m.media-amazon.com/images/I/317Yl00n5gL._SX300_SY300_QL70_FMwebp_.jpg",
    title: "Liquid Salicylic Acid Exfoliant--Facial Exfoliant for Blackheads",
    category: "Beauty",
    price: 70.0,
    quantity: 1,
    lowPrice: 36,
    highPrice: 68,
  },
  {
    id: 48,
    poster:
      "https://m.media-amazon.com/images/I/61J1Mxmh3YL._SX425_.jpg",
    title: "Revitalizing Facial Moisturizer for Sensitive",
    category: "Beauty",
    price: 70.0,
    quantity: 1,
    lowPrice: 36,
    highPrice: 68,
  },

];
export const HOME_KITCHEN_PRODUCTS = [
  {
    id: 16,
    poster: "https://m.media-amazon.com/images/I/71Cjp6gbqVL._AC_SX679_.jpg",
    title: "Damascus Kitchen Knife with Sheath & Giftbox",
    category: "Home & Kitchen",
    price: 50.0, // Your price
    quantity: 1,
    lowPrice: 45,
    highPrice: 55,
    description: "Exceptional Quality: Our chef knife, also known as a chef's knife or Japanese chef knife, is made from high-quality Damascus steel for precision and durability.",
  },
  {
    id: 62,
    poster: "https://m.media-amazon.com/images/I/51LhPuR32NL._AC_SX679_.jpg",
    title: "Dutch Oven with Lid 5.3 Quart, Matte Enamel Finish",
    category: "Home & Kitchen",
    price: 60.0, // Your price
    quantity: 1,
    lowPrice: 55,
    highPrice: 65,
    description: "Effortless Cooking: Experience the joy of seamless cooking with our Dutch Oven, designed for a variety of delicious dishes with even heat distribution.",
  },
  {
    id: 63,
    poster: "https://m.media-amazon.com/images/I/61EiSIICGNL._AC_SX679_.jpg",
    title: "BtArt Coffee Mugs, Set of 4, 16oz, Fine Bone China Porcelain",
    category: "Home & Kitchen",
    price: 25.0, // Your price
    quantity: 1,
    lowPrice: 22,
    highPrice: 30,
    description: "This elegant set of BtArt gray coffee mugs includes 4 fine bone china porcelain mugs, each with a 16-ounce capacity, perfect for savoring your favorite coffee or tea.",
  },
  {
    id: 64,
    poster: "https://m.media-amazon.com/images/I/61EiSIICGNL._AC_SX679_.jpg",
    title: "Nutribullet Personal Blender for Shakes, Smoothies",
    category: "Home & Kitchen",
    price: 80.0, // Your price
    quantity: 1,
    lowPrice: 75,
    highPrice: 90,
    description: "The original Nutribullet has everything you need to take that first step toward a healthier lifestyle.",
  },
  {
    id: 65,
    poster: "https://m.media-amazon.com/images/I/71GpXNB86HL.__AC_SX300_SY300_QL70_FMwebp_.jpg",
    title: "Bento Box Adult Lunch Box",
    category: "Home & Kitchen",
    price: 20.0, // Your price
    quantity: 1,
    lowPrice: 18,
    highPrice: 25,
    description: "Tips: The cover image of the packaging box is a teal bento box, but inside is the correct color bento box you choose.",
  },
  {
    id: 66,
    poster: "https://m.media-amazon.com/images/I/91Ulir9wKXL.__AC_SX300_SY300_QL70_FMwebp_.jpg",
    title: "Mueller Pro-Series 10-in-1, 8 Blade Vegetable Chopper",
    category: "Home & Kitchen",
    price: 35.0, // Your price
    quantity: 1,
    lowPrice: 30,
    highPrice: 40,
    description: "The Original Pro Chopper Refined Since 2013 - Another well-marketed lower-quality white/black model claims to be the original.",
  },
  {
    id: 67,
    poster: "https://m.media-amazon.com/images/I/81I2j0o7zYL.__AC_SX300_SY300_QL70_FMwebp_.jpg",
    title: "GreenPan 13-in-1 Elite Countertop Convection Oven & Air Fryer",
    category: "Home & Kitchen",
    price: 120.0, // Your price
    quantity: 1,
    lowPrice: 110,
    highPrice: 130,
    description: "MULTIFUNCTION DESIGN: Bake. Broil. Air Fry. Toast. Bagel. Pizza. Roast. Dehydrate. Proof. Slow Cook. Defrost. Duo Cook. And More!",
  },
  {
    id: 68,
    poster: "https://m.media-amazon.com/images/I/81N6aDmsvpL.__AC_SX300_SY300_QL70_FMwebp_.jpg",
    title: "Bumblr Oversized Corduroy Sectional Couches for Living Room",
    category: "Home & Kitchen",
    price: 500.0, // Your price
    quantity: 1,
    lowPrice: 450,
    highPrice: 550,
    description: "Sturdy Structure: Our modular sectional sofa features a sturdy wooden frame, providing excellent stability and durability.",
  },
  {
    id: 69,
    poster: "https://m.media-amazon.com/images/I/81wHetavUQL.__AC_SX300_SY300_QL70_FMwebp_.jpg",
    title: "KOZYFLY Boho Rugs for Entryway 2x3 ft Small Area Rugs Washable",
    category: "Home & Kitchen",
    price: 22.0, // Your price
    quantity: 1,
    lowPrice: 20,
    highPrice: 25,
    description: "Check Door Clearance: The 2 x 3 rug is 0.2 inches thick, with reinforced edges up to 0.27 inches.",
  },
  {
    id: 70,
    poster: "https://m.media-amazon.com/images/I/71rvT22VH3L.__AC_SX300_SY300_QL70_FMwebp_.jpg",
    title: "Tineco iFLOOR 3 Breeze Complete Wet Dry Vacuum Cordless Floor Cleaner",
    category: "Home & Kitchen",
    price: 200.0, // Your price
    quantity: 1,
    lowPrice: 180,
    highPrice: 220,
    description: "Tineco iFLOOR 3 Breeze Complete vacuums and mops hard floors at the same time. Easy to store in small spaces but doesn’t compromise on cleaning performance.",
  },
  // Add more products as needed based on the screenshots
];

